<template>
  <div class="file-upload">
    <a-dropdown
      default-value="文件上传"
      v-show="taskDefinitionKey==='PROCESS_SUBMIT'"
    >
      <a-button
        style="width: 120px"
        type="primary"
      >
        <a-icon type="upload"></a-icon>文件上传
      </a-button>
      <a-menu slot="overlay">
        <a-menu-item>
          <a-upload
            :showUploadList="showUploadList"
            :customRequest="handleUpload"
          >
            本地文件上传
          </a-upload>
        </a-menu-item>
        <!-- <a-menu-item>
          <span @click="handleClick">线上文件上传</span>
        </a-menu-item> -->
      </a-menu>
    </a-dropdown>
    <!-- 附件列表 -->
    <div
      class="file-list"
      v-if="data"
    >
      <a-list
        :data-source="data"
        :item-layout="horizontal"
      >
        <a-list-item
          slot="renderItem"
          slot-scope="item"
        >
          <a
            slot="actions"
            @click="FileDown(item)"
          >下载</a>
          <a
            slot="actions"
            v-show="taskDefinitionKey==='PROCESS_SUBMIT'"
            @click="deleteFile(item)"
          >删除</a>
          <a-icon
            class="file-icon"
            type="link"
          />
          <a-list-item-meta :description="item.name"> </a-list-item-meta>
        </a-list-item>
        <div slot="header">
          附件列表：
        </div>
      </a-list>
    </div>
    <!-- 对话框 -->
    <a-modal
      :visible="visible"
      @cancel="cancel"
      @ok="ok"
      width="80%"
    >
      <a-row :gutter="20">
        <a-col
          :md="5"
          :sm="24"
        >
          <a-dropdown
            :trigger="[this.dropTrigger]"
            @visibleChange="dropStatus"
          >
            <a-tree
              style="user-select: none; height: 80vh; overflow-y: auto"
              multiple
              @select="onSelect"
              @check="onCheck"
              :selectedKeys="selectedKeys"
              :checkedKeys="checkedKeys"
              :treeData="departTree"
              :load-data="onLoadData"
              :checkStrictly="true"
              :expandedKeys="iExpandedKeys"
              :autoExpandParent="autoExpandParent"
              @expand="onExpand"
            />
          </a-dropdown>
        </a-col>
        <a-col
          :md="19"
          :sm="24"
          class="search"
        >
          <ax-table
            ref="tableBox"
            :searchForm="searchForm"
            :columns="columns"
            :dataSourceApi="api.getFileList"
            :dataSourceParams="dataSourceParams"
            :sqlParams="sqlParams"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          />
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import api from './api'
import { initGridFormData } from '@/common/tools'
// 表头
const tableHeader = [
  { dataIndex: 'name', title: '文件名称', ellipsis: true, width: 120 },
  { dataIndex: 'description', title: '文件描述', ellipsis: true, width: 120 },
  { dataIndex: 'fileDeptName', title: '文件所属部门', ellipsis: true, width: 120 },
  {
    title: '文件类型',
    dataIndex: 'fileType_dictText',
    ellipsis: true,
    width: 120
  }
]

// 搜索表单
const searchForm = [
  {
    label: '文件名称',
    type: 'input',
    model: 'fileName',
    options: [],
    col: { span: 8 }
  },
  {
    label: '文件类型',
    type: 'select',
    model: 'fileType',
    options: [],
    col: { span: 8 }
  },
  {
    label: '所属部门',
    type: 'select',
    model: 'fileDept',
    options: [],
    col: { span: 8 }
  }
]
export default {
  props: ['fileType', 'reportId', 'taskDefinitionKey'],
  data () {
    return {
      api,
      data: [],
      originalFile: [],
      showUploadList: false,
      visible: false,
      fileTypeOptions: [],
      deptList1: [],
      selectedRowKeys: [],
      dataSourceParams: {},
      sqlParams: { like: ['name'] },
      columns: this.$ax.tools.initColumn(tableHeader),
      horizontal: null,
      searchForm: initGridFormData(searchForm, {
        layout: 'horizontal',
        labelWidth: 70
      }),
      dropTrigger: '',
      selectedKeys: [],
      checkedKeys: [],
      departTree: [],
      iExpandedKeys: [],
      autoExpandParent: true,
      pager: {
        page: 1,
        size: 10,
        total: 0
      }
    }
  },
  async mounted () {
    await this.loadTree()
    this.getList()
    this.fileTypeList()
    this.deptList()
  },
  methods: {
    // 上传文件
    handleUpload ({ file }) {
      console.log(213);
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileBusinessMiodular', this.fileType)
      this.api
        .uploadFile(formData)
        .then((response) => {
          this.$message.success('上传成功')
          this.data.push(response.data)
          console.log(response.data);
          this.$emit('fileUrl',response.data.fileId)
        })
        .catch(() => this.$message.error('上传失败'))
    },

    // 文件回显
    getFileList (businessId, businessType) {
      console.log(businessId, businessType);
      api.getFileDetailList(`businessId=${businessId}&businessType=${businessType}`).then((response) => {
        console.log(response.data.records);
        this.data = response.data.records
        this.originalFile = JSON.parse(JSON.stringify(response.data.records))
      })
    },

    // // 下载文件
    // downFileList (item) {
    //   console.log(item.fileId);
    //   window.open(
    //     `http://192.168.1.110:1089/file/download/?id=${item.fileId}`
    //   )
    // },
      // 下载文件
      FileDown(item) {
      console.log(item);
      this.downloadFile(item.url, item.name);
    },
    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
      };
      xhr.send();
    },


    // 删除文件
    deleteFile (item) {
      this.$emit('delFile',item.fileId)
      this.api
        .deleteFile({ id: item.id, businessId: this.reportId })
        .then(() => {
          this.$message.success('删除成功')
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(() => this.$message.error('删除失败'))
    },
    handleClick () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp('fileType', {
          options: { options: this.fileTypeOptions }
        })
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp('fileDept', {
          options: { options: this.deptList1 }
        })
      })
    },
    cancel () {
      this.visible = false
      this.selectedRowKeys = []
    },
    ok () {
      this.api.getFileDetail({ id: this.selectedRowKeys[0] }).then((res) => {
        this.data.push(res.data)
      })
      this.visible = false
      this.selectedRowKeys = []
    },
    // 选中某个文件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    dropStatus (visible) {
      if (visible === false) {
        this.dropTrigger = ''
      }
    },

    // 选择左侧树形列表查询方法
    onSelect (selectedKeys, e) {
      const record = e.node.dataRef
      this.currSelected = Object.assign({}, record)
      this.selectedKeys = [record.key]
      this.dataSourceParams.fileLbraryId = record.id
      this.dataSourceParams.pageNo = this.pager.pageNo
      this.dataSourceParams.pageSize = this.pager.pageSize
      this.$refs.tableBox.getDataSource()
      this.setValuesToForm(record)
    },

    onCheck (checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked
    },

    onLoadData (treeNode) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          api.fileClassList({ parentId: treeNode.dataRef.id }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.departTree = [...this.departTree]
          resolve()
        }, 1000)
      })
    },

    onExpand (expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    // 树形部分代码  当前选择显示
    getCurrSelectedTitle () {
      return !this.currSelected.title ? '' : this.currSelected.title
    },

    onClearSelected () {
      this.checkedKeys = []
      this.currSelected = {}
      this.selectedKeys = []
    },
    getList () {
      this.dataSourceParams.pageNo = this.pager.pageNo
      this.dataSourceParams.pageSize = this.pager.pageSize
    },
    loadTree () {
      this.$nextTick(() => {
        api.fileClassList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
        })
      })
    },

    setValuesToForm (record) {
      this.crChargeDept = record.id
      this.pager.pageNo = record.total
    },

    setThisExpandedKeys (node) {
      if (node.children && node.children.length > 0) {
        this.iExpandedKeys.push(node.key)
      }
    },
    fileTypeList () {
      api.dictData({ dicKind: 'sys_file_type' }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode }
        })
        this.fileTypeOptions = options
      })
    },
    async deptList () {
      await api.deptList({ parentId: 0 }).then((res) => {
        const options = res.data.records.map((res) => {
          return { label: res.deptName, value: res.id }
        })
        this.deptList1 = options
        // this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          // 'fileDept',
          // { options: { options } }
        // )
      })
    }
  }
}
</script>

<style scoped>
.file-icon {
  margin-right: 5px;
}
</style>
